@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}

/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* css variables */
:root {
  --primary-color: #379cf6;
  --heading-color: #fff;
  --btn-primary-bg: #379cf6;
  --btn-secondary-bg: #090238;
  --team-card-bg: #1b2352;
  --link-color: #fff;
  --link-active: #379cf6;
  --card-bg: linear-gradient(
    45deg,
    rgba(15, 18, 61, 1) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgba(11, 41, 69, 1) 84%
  );
  --counter-color: linear-gradient(
    90deg,
    #02bba6 30%,
    rgba(29, 129, 176, 1) 55%,
    rgba(35, 93, 173, 1) 75%
  );
  --body-bg: rgba(6, 11, 26, 1);
  --newsletter-bg: linear-gradient(
    90deg,
    rgba(27, 9, 115, 1) 20%,
    rgba(23, 9, 96, 1) 38%,
    rgba(14, 9, 56, 1) 100%
  );
  --testimonial-bg: #1a0f4f;
  --font-name: "Roboto", sans-serif;
  --small-text-color: rgba(255, 255, 255, 0.774);
  --icon-bg:#151e4f;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body{
  background: var(--body-bg);
  font-family: var(--font-name);
}

a{
  text-decoration: none;
  color: var(--link-color);
}

h1,
h2{
  color: var(--heading-color);
  font-size: 2rem;
}

section{
  padding: 60px 0px;
}

.container{
  width: 100%;
  padding: 0px 10px 0px 0px;
  margin: auto;
}

.description {
  color: var(--small-text-color);
  line-height: 30px;
  font-size: 0.9rem;

}

.subtitle {
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 400;
  margin-bottom: 20px;
}






