.hero__wrapper{
    display: flex;
    justify-content: space-between;
}

.hero__content,
.hero__img {
    width: 70%;
}

.hero__img img{
    width: 100%;
    padding-top: 20rem;
    padding-right: 2rem;
    border-radius: 50%;
    cursor: pointer;
}

.hero__content {
    padding-top: 40px;
}

.hero__content h2{
    font-size: 2rem;
    line-height: 50px;
}

.hero__content p {
    margin-top: 40px;
    font-size: 1.1rem;
}

.highlight{
    color: var(--primary-color);
}

.hero__btns{
    margin-top: 40px;
    display: flex;
    align-items: center;
    column-gap: 2rem;
}

.primary__btn, .secondary__btn {
    padding: 0.8rem 1.5rem;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 0.4rem;
    cursor: pointer;

}

.primary__btn{
    background: var(--btn-secondary-bg);
    border: 1px solid var(--btn-primary-bg);
}

.secondary__btn {
    background: var(--btn-primary-bg);
}
/* .object-col {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    border: none;
    margin-top: 60px;
    margin-left: 50px;
    align-items: center;
  } */


