.about__wrapper {
    display: flex;
    column-gap: 2rem;
  }
  
  .about__content,
  .about__img {
    width: 50%;
  }
  
  .about__img img {
    width: 100%;
    height: 86%;
    object-fit: cover;
    border-radius: 50rem;
    padding: 10px;
    padding-top: 10rem;
    padding-left: 10rem;
   
  }
  
  .about__content-desc {
    margin-top: 30px;
  }
  
  .choose__item-wrapper {
    margin-top: 20px;
  }
  
  .choose__us-item {
    padding: 20px 20px 20px 0;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-top: 15px;
  }
  
  .choose__us-icon {
    width: 50px;
    height: 60px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
  }
  
  .choose__us-icon i {
    color: var(--primary-color);
    font-size: 2rem;
  }
  
  .choose__us-title {
    color: #fff;
    font-weight: 600;
  }
  
  .light-theme .choose__us-title {
    color: #0c123d !important;
  }
  
  @media only screen and (max-width:992px) {
    .about__content h2 {
      font-size: 1.5rem;
    }
  }
  
  @media only screen and (max-width:768px){
    .about__wrapper{
      flex-direction: column;
    }
  
    .about__content, .about__img {
      width: 100%;
    }
  
    .about__img img {
      height: 400px !important;
    }
  
    .about__content {
      margin-bottom: 30px;
    }
  }